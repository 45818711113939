"use client";
import store from "@src/store";
import "@src/i18next";
import { Provider } from "react-redux";
import { NavBar, Notifications, ContactDialog } from "@components/common";
export default function MainContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Provider store={store}>
      <main>
        <NavBar />
        <div className="min-h-[640px] h-screen">{children}</div>
      </main>
      <ContactDialog />
      <Notifications />
    </Provider>
  );
}
